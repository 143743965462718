import set from 'lodash/set';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import Translation from 'components/data/Translation';
import { GenericSvgIcon } from 'components/ui-components/GenericIcon';
import ViewState from 'components/data/ViewState';
import User from 'components/data/User';
import Template, { DesignerSettings, State, TemplateData, View } from '../types/template.type';
import { getTemplateData } from './data.helpers';
import Layer, { LayerToAdd } from '../types/layer.type';
import LayerProperties, { AllProperties, PositionOptions, SizeUnitOptions, Visibility } from '../types/layerProperties.type';
import FrameType from '../types/frameTypes.type';
import { LAYER_COLLAPSED_KEY, OPEN_ON_MOUNT_KEY } from '../constants';
import { LayerPropertiesHelpers } from './layer-properties.helpers';
import { defaultLayerProperties } from '../config/layer-properties/default-layer-properties';
import { TemplateVersionHelpers } from './template-version.helpers';
import { FontHelpers } from './font.helpers';
import { generateKey } from '../utils/generateKey';
import TemplateDesignerStore, { MultiModel } from '../data/template-designer-store';
import { defaultContainerProperties } from '../config/layer-properties/container-properties';
import { DynamicLayerInput } from '../types/dynamicLayer.type';
import { ActiveAnimationType } from '../types/animation.type';
import { TimelineHelpers } from './timeline.helpers';

class LayerHelpers {
    static MIN_TITLE_LENGTH = 1;
    static MAX_TITLE_LENGTH = 50;

    /**
     * Add a new layer to the template.
     * @param layerType - The type of the layer to add.
     * @param layerSubTypes - The sub types of the layer to add.
     */
    static addLayers = (layerToAdd: LayerToAdd[]): void => {
        const selectedLayer = getTemplateData<State['selectedLayers']>('state.selectedLayers')?.[0];
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const layers = getTemplateData<Template['layers']>('layers');
        const frameLayers = layers[frameType];

        const changes: MultiModel = [];
        const layersShouldReverse = !TemplateVersionHelpers.layersShouldNotReverse();

        /**
         * Recursive function that creates new layers and adds properties to the store.
         * @param layersToAdd - Layers object to convert.
         * @param isChild - If the current layer is a child.
         * @returns New layers
         */
        function addLayers(layersToAdd: LayerToAdd[], isChild?: boolean) {
            return layersToAdd.map((layerToAdd) => {
                const { layerType, propertyKey, children } = layerToAdd;

                const defaultProperties = LayerPropertiesHelpers.getDefaultProperties(layerType, propertyKey);

                if (!defaultProperties) {
                    throw new Error(`No default properties found for: ${layerType}`);
                }

                const title = propertyKey
                    ? Translation.get(`general.propertyKeys.${propertyKey}`, 'template-designer')
                    : Translation.get(`general.layerTypes.${layerType}`, 'template-designer');

                const newLayer: Layer = {
                    key: generateKey(layerType),
                    type: layerType,
                    title,
                    children: children ? addLayers(children, true) : []
                };

                newLayer.title = LayerHelpers.generateLayerTitle(newLayer, frameLayers);

                if (layersShouldReverse) {
                    newLayer.children?.reverse();
                }

                if (isChild) {
                    defaultProperties.position = PositionOptions.Relative;
                } else {
                    defaultProperties.position = PositionOptions.Absolute;
                }

                let newLayerPropertiesLayer = cloneDeep(defaultLayerProperties);
                newLayerPropertiesLayer.properties = defaultProperties;
                newLayerPropertiesLayer = LayerPropertiesHelpers.removeDefaultProperties(newLayerPropertiesLayer, layerType);

                if (layerToAdd.data) {
                    layerToAdd.data.forEach(([key, value]) => {
                        set(newLayerPropertiesLayer, key, value);
                    });
                }

                changes.push([`layerProperties.general.${frameType}.${newLayer.key}`, newLayerPropertiesLayer]);

                return newLayer;
            });
        }

        const newLayers = addLayers(layerToAdd);

        if (layersShouldReverse) {
            newLayers.reverse();
        }

        /**
         * If the selected layer is a container, add the new layer to the children of the container layer.
         */
        if (selectedLayer?.type === 'container') {
            let layerAdded = false;

            const addToChildren = (layers: Layer[]) => {
                for (const layer of layers) {
                    if (layer.key === selectedLayer.key && layer.children) {
                        layer.children.push(...newLayers);
                        layerAdded = true;
                        break;
                    }

                    if (layer.children && layer.children.length > 0) {
                        addToChildren(layer.children);
                    }
                }
            };

            addToChildren(frameLayers);

            if (!layerAdded) {
                frameLayers.unshift(...newLayers);
            }

            newLayers.forEach((newLayer) => {
                changes.push([`layerProperties.general.${frameType}.${newLayer.key}.properties.position`, PositionOptions.Relative]);
            });
        } else {
            /**
             * Check if the selected layer is a child of a container.
             * If it is, add the new layer to the children of the container layer.
             * If it isn't, add the new layer to the top of the layers.
             */
            let layerAdded = false;

            const isChildOfContainer = (layers: Layer[]) => {
                for (const layer of layers) {
                    const isChild = !!(layer.children?.length && layer.children.find((child) => child.key === selectedLayer?.key));

                    if (isChild && layer.children) {
                        layer.children.unshift(...newLayers);
                        layerAdded = true;
                        newLayers.forEach((newLayer) => {
                            changes.push([`layerProperties.general.${frameType}.${newLayer.key}.properties.position`, PositionOptions.Relative]);
                        });
                        break;
                    }

                    if (layer.children && layer.children.length > 0) {
                        isChildOfContainer(layer.children);
                    }
                }
            };

            isChildOfContainer(frameLayers);

            if (!layerAdded) {
                frameLayers.unshift(...newLayers);
            }
        }

        TemplateDesignerStore.save([
            ...changes,
            ['view.showTab', 'layerEdit', false],
            [`layers.${frameType}`, frameLayers],
            ['state.selectedLayers', [newLayers[0]]],
            ['state.selectedFormats', ['general'], false],
            ['state.activeAnimations', [], false],
            ['state.openItemTree', true, false]
        ]);
    };

    /**
     * Check what the layer title should be.
     * If there is already a layer with the same name, add a number behind it.
     * If there is already a layer with the same name and and a number, increase the number.
     * @param newLayer - Layer to check for the title.
     * @param layers - Layers to search in to check the layer title.
     * @returns New layer title
     */
    static generateLayerTitle = (newLayer: Layer, layers: Layer[]): string => {
        const regexNumber = /\d+$/;

        /**
         * Helper function to extract number from title.
         * @param title - Title with a potentional number.
         * @returns The number from the title.
         */
        const extractNumber = (title: string): number => {
            const match = title.match(regexNumber);
            return match ? parseInt(match[0]) : 0;
        };

        /**
         * Helper function to get title without the number.
         * @param title - Title to extract the number from.
         * @returns New title without a number in the end.
         */
        const getTitleWithoutNumber = (title: string): string => {
            return title.replace(regexNumber, '').trim();
        };

        const originalLayerTitleWithoutNumber = getTitleWithoutNumber(newLayer.title);
        let highestNumber = extractNumber(newLayer.title);

        /**
         * Check each layer and check if has the same name as the duplicated layer.
         * If it has, then increase the number so the duplicated layer doesn't have the same name elsewhere.
         * @param layers - Layers to search in.
         */
        const findLayerWithSimilarTitle = (layers: Layer[]) => {
            layers.forEach((layer) => {
                const layerTitleWithoutNumber = getTitleWithoutNumber(layer.title);
                if (layerTitleWithoutNumber === originalLayerTitleWithoutNumber) {
                    const numberInLayer = extractNumber(layer.title);
                    if (numberInLayer > highestNumber) {
                        highestNumber = numberInLayer;
                    }
                }

                if (layer.children && layer.children.length) {
                    findLayerWithSimilarTitle(layer.children);
                }
            });
        };

        findLayerWithSimilarTitle(layers);

        const newLayerTitle = `${originalLayerTitleWithoutNumber} ${highestNumber + 1}`;
        return newLayerTitle;
    };

    /**
     * Get all layers from the current frame and check if certain layer types should be filtered out.
     * @param layers - The layers to get the layers from.
     * @param frameType - The frame type to get the layers from.
     * @param enableAnimations - If animations are enabled.
     * @param enableLottie - If Lottie is enabled.
     * @param layersShouldReverse - If the layers should be reversed.
     * @returns The layers of the given frame type.
     */
    static getLayers = (
        layers?: Template['layers'],
        frameType?: View['frameType'],
        options?: {
            enableAnimations?: DesignerSettings['enableAnimations'];
            enableLottie?: DesignerSettings['enableLottie'];
            enableVideo?: DesignerSettings['enableVideo'];
            enableAudio?: DesignerSettings['enableAudio'];
            layersShouldReverse?: boolean;
        }
    ): Layer[] => {
        if (layers === undefined) layers = getTemplateData<Template['layers']>('layers');
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');

        const {
            enableAnimations = getTemplateData<DesignerSettings['enableAnimations']>('designerSettings.enableAnimations'),
            enableLottie = getTemplateData<DesignerSettings['enableLottie']>('designerSettings.enableLottie'),
            enableVideo = getTemplateData<DesignerSettings['enableVideo']>('designerSettings.enableVideo'),
            enableAudio = getTemplateData<DesignerSettings['enableAudio']>('designerSettings.enableAudio'),
            layersShouldReverse = !TemplateVersionHelpers.layersShouldNotReverse()
        } = options ?? {};

        const frameLayers = layers[frameType];

        if (enableAnimations && enableLottie && enableVideo && enableAudio) {
            if (layersShouldReverse) {
                return frameLayers.slice().reverse();
            }

            return frameLayers;
        }

        /**
         * Recursive function that filters out the layers of the given layer types.
         * @param layers - The layers to filter.
         * @param layerTypesToBeFiltered - The layer types to be filtered.
         * @returns The filtered layers.
         */
        function filterLayerTypes(layers: Layer[], layerTypesToBeFiltered: Layer['type'][]) {
            return layers.reduce((acc, item) => {
                const newItem = item;

                if (item.children?.length) {
                    newItem.children = filterLayerTypes(item.children, layerTypesToBeFiltered);
                }

                if (!layerTypesToBeFiltered.includes(newItem.type)) {
                    acc.push(newItem);
                }

                return acc;
            }, [] as Layer[]);
        }

        const layerTypesToBeFiltered: Layer['type'][] = [];

        // If animations are disabled, filter out the video and audio layers.
        if (!enableAnimations) {
            layerTypesToBeFiltered.push('video', 'audio');
        }

        // If Lottie is disabled, filter out the Lottie layers.
        if (!enableLottie) {
            layerTypesToBeFiltered.push('lottie');
        }

        // If video is disabled, filter out the video layers.
        if (!enableVideo) {
            layerTypesToBeFiltered.push('video');
        }

        // If audio is disabled, filter out the audio layers.
        if (!enableAudio) {
            layerTypesToBeFiltered.push('audio');
        }

        if (layersShouldReverse && frameLayers) {
            return cloneDeep(filterLayerTypes(frameLayers, layerTypesToBeFiltered)).reverse();
        }

        return filterLayerTypes(frameLayers, layerTypesToBeFiltered);
    };

    /**
     * Select the layer and deselect all other layers.
     * @param layerKey - The key of the layer to select.
     */
    static selectLayer = (layerKey: Layer['key']): void => {
        const selectedLayer = getTemplateData<State['selectedLayers']>('state.selectedLayers')[0];
        if (selectedLayer && selectedLayer.key === layerKey) return;

        const layer =
            layerKey === 'formatData'
                ? {
                      children: [],
                      type: 'format',
                      key: 'formatData',
                      title: Translation.get('general.labels.format', 'template-designer')
                  }
                : LayerHelpers.findLayer(layerKey);

        if (!layer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        const newSelectedLayers = [layer];

        TemplateDesignerStore.save(
            [
                ['state.selectedLayers', newSelectedLayers],
                [
                    'state.activeAnimations',
                    [
                        {
                            type: ActiveAnimationType.Visibility,
                            layer: layerKey
                        }
                    ]
                ],
                ['state.openItemTree', false]
            ],
            { saveToHistory: false }
        );
    };

    /**
     * Deselect the layer.
     * @param layerKey - The key of the layer to deselect.
     */
    static deselectLayer = (layerKey: Layer['key']): void => {
        const layer = LayerHelpers.findLayer(layerKey);

        if (!layer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        TemplateDesignerStore.save(
            [
                ['state.selectedLayers', []],
                ['state.activeAnimations', []]
            ],
            { saveToHistory: false }
        );
    };

    /**
     * Wrap the given layer into a container.
     * @param layerKey - The key of the layer to wrap in a container.
     */
    static wrapContainer = (layerKey: Layer['key']): void => {
        const layer = LayerHelpers.findLayer(layerKey);

        if (!layer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        const formats = getTemplateData<Template['formats']>('formats');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const layers = getTemplateData<Template['layers']>('layers');
        const frameLayers = layers[frameType];

        const newContainer: Layer = {
            key: generateKey('container'),
            type: 'container',
            title: Translation.get('general.layerTypes.container', 'template-designer'),
            children: [layer]
        };

        /**
         * Recursive function that finds the layer and replaces it with the new container.
         * @param layers - The layers to search in.
         */
        const replaceLayer = (layers: Layer[]): void => {
            const layerIndex = layers.findIndex((frameLayer) => frameLayer.key === layer.key);

            if (layerIndex !== -1) {
                layers.splice(layerIndex, 1, newContainer);
            } else {
                for (const l of layers) {
                    if (l.children && l.children.length > 0) {
                        replaceLayer(l.children);
                    }
                }
            }
        };

        replaceLayer(frameLayers);

        const changes: MultiModel = [
            [`layers.${frameType}`, frameLayers],
            ['state.selectedLayers', [newContainer]]
        ];

        /**
         * Get the size of the layer and return the size with the correct size.
         * @param size - The size to get the correct size of.
         * @returns The size with the correct size.
         */
        const getSize = (size: AllProperties['width'] | AllProperties['height']): AllProperties['width'] | AllProperties['height'] => {
            if (size.unit === SizeUnitOptions.Px) {
                return size;
            }

            if (size.value === 100) {
                return size;
            }

            return { value: 100, unit: SizeUnitOptions['%'], resize: size.resize };
        };

        // Create new layer properties for the container.
        const newLayerGeneralProperties = cloneDeep(defaultLayerProperties);
        newLayerGeneralProperties.properties = cloneDeep(defaultContainerProperties);

        const originalLayerModel = `layerProperties.general.${frameType}.${layer.key}`;
        const originalLayerModelProperties = `${originalLayerModel}.properties`;

        // Copy some general properties of the layer to the container.
        const generalX = getTemplateData<AllProperties['x']>(`${originalLayerModelProperties}.x`);
        const generalY = getTemplateData<AllProperties['y']>(`${originalLayerModelProperties}.y`);
        const generalWidth = getTemplateData<AllProperties['width']>(`${originalLayerModelProperties}.width`);
        const generalHeight = getTemplateData<AllProperties['height']>(`${originalLayerModelProperties}.height`);

        const generalMeasurepoint = getTemplateData<AllProperties['measurePoint']>(`${originalLayerModelProperties}.measurePoint`);
        const generalHorizontalAlign = getTemplateData<AllProperties['horizontalAlign']>(`${originalLayerModelProperties}.horizontalAlign`);
        const generalVerticalAlign = getTemplateData<AllProperties['verticalAlign']>(`${originalLayerModelProperties}.verticalAlign`);

        const generalVisibility = getTemplateData<Visibility>(`${originalLayerModel}.visibility`);
        set(newLayerGeneralProperties, 'properties.x', generalX);
        set(newLayerGeneralProperties, 'properties.y', generalY);
        set(newLayerGeneralProperties, 'properties.measurePoint', generalMeasurepoint);
        set(newLayerGeneralProperties, 'properties.horizontalAlign', generalHorizontalAlign);
        set(newLayerGeneralProperties, 'properties.verticalAlign', generalVerticalAlign);

        set(newLayerGeneralProperties, 'properties.width', getSize(generalWidth));
        set(newLayerGeneralProperties, 'properties.height', getSize(generalHeight));
        set(newLayerGeneralProperties, 'visibility', generalVisibility);

        // Reset some general properties of the layer.
        changes.push([`${originalLayerModelProperties}.x`, { value: '', unit: 'px' }]);
        changes.push([`${originalLayerModelProperties}.y`, { value: '', unit: 'px' }]);

        // Set the position of the layer to relative.
        changes.push([`${originalLayerModelProperties}.position`, PositionOptions.Relative]);
        changes.push([`${originalLayerModelProperties}.measure`, PositionOptions.Relative]);

        // Save the new layer properties.
        changes.push([`layerProperties.general.${frameType}.${newContainer.key}`, newLayerGeneralProperties]);

        // Check every format overwrite.
        formats.forEach((format) => {
            const formatVisibility = getTemplateData<[number, number]>(`layerProperties.${format.key}.${frameType}.${layerKey}.visibility`);
            if (formatVisibility) {
                changes.push([`layerProperties.${format.key}.${frameType}.${newContainer.key}.visibility`, formatVisibility]);
            }

            // Get the format properties.
            const formatProperties = getTemplateData<LayerProperties['properties']>(`layerProperties.${format.key}.${frameType}.${layerKey}.properties`);
            if (!formatProperties) return;

            // Set the x properties to the container and remove them from the layer.
            if (formatProperties.x !== undefined) {
                changes.push([`layerProperties.${format.key}.${frameType}.${newContainer.key}.properties.x`, formatProperties.x]);
                changes.push([`layerProperties.${format.key}.${frameType}.${layerKey}.properties.position`, PositionOptions.Relative]);
                changes.push([`layerProperties.${format.key}.${frameType}.${layerKey}.properties.x`, undefined]);
            }

            // Set the y properties to the container and remove them from the layer.
            if (formatProperties.y !== undefined) {
                changes.push([`layerProperties.${format.key}.${frameType}.${newContainer.key}.properties.y`, formatProperties.y]);
                changes.push([`layerProperties.${format.key}.${frameType}.${layerKey}.properties.position`, PositionOptions.Relative]);
                changes.push([`layerProperties.${format.key}.${frameType}.${layerKey}.properties.y`, undefined]);
            }

            // Set the width property to hug.
            if (formatProperties.width !== undefined) {
                changes.push([`layerProperties.${format.key}.${frameType}.${newContainer.key}.properties.width`, getSize(formatProperties.width)]);
            }

            // Set the height property to hug.
            if (formatProperties.height !== undefined) {
                changes.push([`layerProperties.${format.key}.${frameType}.${newContainer.key}.properties.height`, getSize(formatProperties.height)]);
            }

            // Set the measurepoint to the container and remove them from the layer.
            if (formatProperties.measurePoint !== undefined) {
                changes.push([`layerProperties.${format.key}.${frameType}.${newContainer.key}.properties.measurePoint`, formatProperties.measurePoint]);
            }

            if (formatProperties.horizontalAlign !== undefined) {
                changes.push([`layerProperties.${format.key}.${frameType}.${newContainer.key}.properties.horizontalAlign`, formatProperties.horizontalAlign]);
            }

            if (formatProperties.verticalAlign !== undefined) {
                changes.push([`layerProperties.${format.key}.${frameType}.${newContainer.key}.properties.verticalAlign`, formatProperties.verticalAlign]);
            }
        });

        TemplateDesignerStore.save(changes, { saveToHistory: true });
    };

    /**
     * Check if the child layer is a child of the parent layer.
     * @param parent - The parent layer.
     * @param child - The child layer.
     * @returns True if the child layer is a child of the parent layer.
     */
    static isChildLayer = (parent: Layer, child: Layer): boolean => {
        if (parent.key === child.key) return true;

        if (parent.children && parent.children.length > 0) {
            for (const childLayer of parent.children) {
                if (LayerHelpers.isChildLayer(childLayer, child)) {
                    return true;
                }
            }
        }

        return false;
    };

    /**
     * Show or hide the layer.
     * @param layerKey - The key of the layer.
     */
    static toggleLayerVisibility = (layerKey: Layer['key']): void => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const totalFormats = getTemplateData<Template['formats']>('formats', { clone: false }).length;
        const selectedFormats = getTemplateData<State['selectedFormats']>('state.selectedFormats');
        const selectedLayer = getTemplateData<State['selectedLayers']>('state.selectedLayers')[0];
        const changes: MultiModel = [];

        const selectedFormat = selectedFormats[0];
        const formatsToUpdate = (() => {
            if (totalFormats === selectedFormats.length || selectedFormat === 'general') {
                return ['general'];
            }

            return selectedFormats;
        })();

        const generalDisplay: LayerProperties['properties']['display'] = getTemplateData(`layerProperties.general.${frameType}.${layerKey}.properties.display`);
        const formatDisplay: LayerProperties['properties']['display'] = getTemplateData(
            `layerProperties.${selectedFormat}.${frameType}.${layerKey}.properties.display`
        );

        /**
         * If the selected format is general, toggle the general display.
         * Otherwise check if the format display is defined. If it is, toggle the format display.
         * If it isn't, toggle the general display.
         */
        const newDisplay = (() => {
            if (formatDisplay === undefined && generalDisplay === undefined) {
                return false;
            }

            if (selectedFormat === 'general') {
                return !generalDisplay;
            }

            if (formatDisplay === undefined) {
                return !generalDisplay;
            }

            return !formatDisplay;
        })();

        formatsToUpdate.forEach((selectedFormat) => {
            changes.push([`layerProperties.${selectedFormat}.${frameType}.${layerKey}.properties.display`, newDisplay]);
        });

        if (selectedLayer?.key === layerKey) changes.push(['state.selectedLayers', []]);

        TemplateDesignerStore.save(changes);
    };

    /**
     * Find the layer in the current or a frame type.
     * @param layerKey - The key of the layer.
     * @param frameType - The frame type to search in. Is by default the current frame type. If false search in all frametypes.
     * @param layers - The layers to search in. Is by default the current layers.
     * @returns The found layer or null.
     */
    static findLayer(
        layerKey: Layer['key'],
        frameType: View['frameType'] | false = getTemplateData<View['frameType']>('view.frameType'),
        layers?: Template['layers'],
        templateType?: Template['templateData']['type']
    ): Layer | null {
        if (layers === undefined) layers = getTemplateData<Template['layers']>('layers');
        if (templateType === undefined) templateType = getTemplateData<Template['templateData']['type']>('templateData.type');

        const frameTypes = (() => {
            if (['dynamicAfterEffects', 'dynamicInDesign'].includes(templateType) || frameType === false) {
                return Object.keys(layers);
            }

            return [frameType];
        })();

        const findLayerByKey = (layers: Layer[]) => {
            for (const layer of layers) {
                if (layer.key === layerKey) {
                    return layer;
                }

                if (layer.children && layer.children.length > 0) {
                    const foundLayer = findLayerByKey(layer.children);
                    if (foundLayer) {
                        return foundLayer;
                    }
                }
            }

            return null;
        };

        // Loop over frameTypes to search in.
        for (const loopFrameType of frameTypes) {
            const frameLayers = layers[loopFrameType];
            const foundLayer = findLayerByKey(frameLayers);

            if (foundLayer !== null) {
                return foundLayer;
            }
        }

        return null;
    }

    /**
     * Find the layer in the current frame type.
     * @param layerKey - The key of the layer.
     * @returns The found layer or null.
     */
    static findLayerByType(
        layerType: Layer['type'],
        frameType: View['frameType'] | false = getTemplateData<View['frameType']>('view.frameType'),
        layers?: Template['layers']
    ): Layer | null {
        if (layers === undefined) layers = getTemplateData<Template['layers']>('layers');

        const frameTypes = (() => {
            if (frameType === false) return Object.keys(layers);
            return [frameType];
        })();

        const findLayer = (layers: Layer[]) => {
            for (const layer of layers) {
                if (layer.type === layerType) {
                    return layer;
                }

                if (layer.children && layer.children.length > 0) {
                    const foundLayer = findLayer(layer.children);
                    if (foundLayer) {
                        return foundLayer;
                    }
                }
            }

            return null;
        };

        // Loop over frameTypes to search in.
        for (const loopFrameType of frameTypes) {
            const frameLayers = layers[loopFrameType];
            const foundLayer = findLayer(frameLayers);

            if (foundLayer !== null) {
                return foundLayer;
            }
        }

        return null;
    }

    /**
     * Find the top most parent layer of the given layer.
     * @param layers - The layers to search in.
     * @param layerKey - The key of the layer to find the parent of.
     * @param parent - The parent layer.
     * @returns The parent layer or null.
     */
    static findTopLayerByKey = (layers: Layer[], layerKey: Layer['key']): Layer | null => {
        for (let i = 0; i < layers.length; i++) {
            const layer = layers[i];
            if (layer.key === layerKey) {
                return layer;
            }

            if (layer.children && layer.children.length > 0) {
                const result = this.findTopLayerByKey(layer.children, layerKey);
                if (result) {
                    return layer;
                }
            }
        }

        return null;
    };

    /**
     * Find the first parent layer of the given layer.
     * @param layers - The layers to search in.
     * @param layerKey - The key of the layer to find the parent of.
     * @param parent - The parent layer.
     * @returns The parent layer or null.
     */
    static findFirstParentByKey = (layers: Layer[], layerKey: Layer['key'], parent: Layer | null = null): Layer | null => {
        for (const layer of layers) {
            if (layer.key === layerKey) {
                return parent;
            }

            if (layer.children && layer.children.length > 0) {
                // Recursively search in the children
                const result = this.findFirstParentByKey(layer.children, layerKey, layer);
                if (result) {
                    return result;
                }
            }
        }

        return null;
    };

    /**
     * Delete the layer from the template.
     * @param layerKey - The key of the layer to delete.
     */
    static deleteLayer(layerKey: Layer['key'], frameType?: FrameType['key']): void {
        const layer = LayerHelpers.findLayer(layerKey, frameType);

        if (!layer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');
        const layers = getTemplateData<Template['layers']>('layers');
        let frameLayers = layers[frameType];
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');

        const formats = getTemplateData<Template['formats']>('formats', { clone: false });

        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        let frameDynamicLayers = dynamicLayers[frameType];

        const feedMapping = getTemplateData<Template['dataVariables']>('dataVariables');
        const frameFeedMapping = feedMapping[frameType];

        let largeMedia = getTemplateData<State['largeMedia']>('state.largeMedia');

        const templateId = getTemplateData<Template['id']>('id');
        let collapsedLayers = ViewState.get('templateDesigner', LAYER_COLLAPSED_KEY + '_' + templateId, 'session') ?? [];

        /**
         * Recursive function that updates the store values
         * handles target layer and all children layers
         * @param layer
         */
        const removeAllProperties = (layer: Layer) => {
            const removeLayer = (layers: Layer[], layerKey: Layer['key']): Layer[] => {
                const filtered = layers.filter((entry) => entry.key !== layerKey);
                return filtered.map((entry) => {
                    if (!entry.children || !entry.children.length) return entry;
                    return { ...entry, children: removeLayer(entry.children, layerKey) };
                });
            };

            const updateDynamicLayers = (items: DynamicLayerInput[]): DynamicLayerInput[] => {
                return items.reduce((inputs, input) => {
                    if (
                        input.type === 'divider' ||
                        input.type === 'alert' ||
                        input.type === 'dropdown' ||
                        input.type === 'feedSelectorInput' ||
                        input.type === 'customInput'
                    ) {
                        inputs.push(input);
                    } else if (input.type === 'group') {
                        input.children = updateDynamicLayers(input.children || []);
                        inputs.push(input);
                    } else {
                        if (!('layerKey' in input) || ('layerKey' in input && input.layerKey === layer.key)) {
                            return inputs;
                        }

                        if (input.children && input.children.length > 0) {
                            input.children = updateDynamicLayers(input.children);
                        }

                        inputs.push(input);
                    }

                    return inputs;
                }, [] as DynamicLayerInput[]);
            };

            // Remove layer.
            frameLayers = removeLayer(frameLayers, layer.key);

            // Remove inputs.
            frameDynamicLayers = updateDynamicLayers(frameDynamicLayers);

            // Remove layer key from the layerProperties.
            delete layerProperties.general[frameType][layer.key];

            // Remove format overwrites.
            formats.forEach((format) => delete layerProperties[format.key][frameType][layer.key]);

            // Remove from feedMapping.
            Object.keys(frameFeedMapping).forEach((key) => {
                if (key.includes(layer.key)) {
                    delete frameFeedMapping[key];
                }
            });

            largeMedia = largeMedia?.filter((media) => media.layer !== layer.key);

            // Also remove children.
            if (layer.children && layer.children.length > 0) {
                layer.children.forEach(removeAllProperties);
            }

            // Remove open on mount from session storage.
            sessionStorage.removeItem(OPEN_ON_MOUNT_KEY + layer.key);

            // Remove layer from collapsed layers.
            collapsedLayers = collapsedLayers.filter((collapsedLayer) => collapsedLayer !== layer.key);
        };

        removeAllProperties(layer);

        if (layer.type === 'text') {
            FontHelpers.removeLayerFromMissingFonts(layer.key);
        }

        const changes: MultiModel = [
            ['state.selectedLayers', []],
            [`layers.${frameType}`, frameLayers],
            ['layerProperties', layerProperties],
            [`dynamicLayers.${frameType}`, frameDynamicLayers],
            [`dataVariables.${frameType}`, frameFeedMapping],
            ['view.showTab', 'layerEdit'],
            ['state.largeMedia', largeMedia]
        ];

        const newConflictingAnimations = TimelineHelpers.getNewConflictingAnimations(frameType, layer.key);
        changes.push(['state.conflictingAnimations', newConflictingAnimations]);

        const activeAnimations = getTemplateData<State['activeAnimations']>('state.activeAnimations');

        // Remove the layer from the active animations.
        const newActiveAnimations = activeAnimations.filter((animation) => animation.layer !== layer.key);
        changes.push(['state.activeAnimations', newActiveAnimations]);

        // Save new collapsed state.
        ViewState.set('templateDesigner', LAYER_COLLAPSED_KEY + '_' + templateId, collapsedLayers, 'session');

        TemplateDesignerStore.save(changes);
    }

    /**
     * Change the property of the layer.
     * @param layerKey - The key of the layer to change the property of.
     * @param property - The property to change.
     * @param value - The new value of the property.
     */
    static changeLayerProperty(layerKey: Layer['key'], property: keyof Layer, value: Layer[keyof Layer]): void {
        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        const layers = getTemplateData<Template['layers']>('layers');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const frameLayers = layers[frameType];

        /**
         * Recursively change the layer title in the dynamicLayers inputs.
         * @param input - The input to change the layer title of.
         * @param layerKey - The key of the layer to change the title of.
         * @returns Modfiied inputs with the input layer title.
         */
        const changeLayerInInput = (input: DynamicLayerInput, layerKey: Layer['key']): DynamicLayerInput => {
            if (input.type === 'group' && input.children && input.children.length > 0) {
                input.children = input.children.map((child) => changeLayerInInput(child, layerKey));
            } else {
                if ('children' in input) {
                    input.children = input.children.map((child) => changeLayerInInput(child, layerKey));
                }
            }

            return input;
        };

        /**
         * Recursively change the property of the layer.
         * @param layers - Layers on the current frame.
         * @param layerKey - The key of the layer to change the property of.
         * @returns Modfiied layers with the new value.
         */
        const changeProperty = (layers: Layer[], layerKey: Layer['key']): Layer[] => {
            return layers.map((entry) => {
                if (entry.key === layerKey) {
                    return { ...entry, [property]: value };
                } else if (entry.children?.length) {
                    return { ...entry, children: changeProperty(entry.children, layerKey) };
                }

                return entry;
            });
        };

        if (property === 'title') {
            // Update the layer name in the dynamicLayer inputs.
            dynamicLayers[frameType].map((input) => changeLayerInInput(input, layerKey));
        }

        const newLayers = changeProperty(frameLayers, layerKey);
        TemplateDesignerStore.save([
            [`layers.${frameType}`, newLayers],
            ['dynamicLayers', dynamicLayers]
        ]);
    }

    /**
     * Copy the layer in the same position with all the layer properties.
     * @param layerKey - The key of the layer to duplicate.
     */
    static duplicateLayer(layerKey: Layer['key']): void {
        const layer = LayerHelpers.findLayer(layerKey);

        if (!layer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        const canEditLayer = LayerHelpers.canEditMediaLayer(layer);
        if (!canEditLayer) return;

        const formats = getTemplateData<Template['formats']>('formats');
        const layers = getTemplateData<Template['layers']>('layers');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const frameLayers = cloneDeep(layers[frameType]);
        const changes: MultiModel = [];

        /**
         * Duplicate layer with all properties.
         * @param layer - Layer to duplicate.
         * @returns - Duplicated layer.
         */
        const duplicateLayer = (layer: Layer): Layer => {
            const duplicatedLayer: Layer = cloneDeep(layer);
            duplicatedLayer.key = generateKey(layer.type);

            // Retrieve and store the styling information using the original key.
            const generalProperties = getTemplateData<LayerProperties>(`layerProperties.general.${frameType}.${layer.key}`);
            changes.push([`layerProperties.general.${frameType}.${duplicatedLayer.key}`, generalProperties]);

            formats.forEach((format) => {
                const formatOverwrites = getTemplateData<LayerProperties>(`layerProperties.${format.key}.${frameType}.${layer.key}`);
                if (!formatOverwrites) return;
                changes.push([`layerProperties.${format.key}.${frameType}.${duplicatedLayer.key}`, formatOverwrites]);
            });

            // Recursively duplicate children
            if (layer.children && layer.children.length > 0) {
                duplicatedLayer.children = layer.children.map((child) => duplicateLayer(child));
            }

            return duplicatedLayer;
        };

        // Call the recursive function for the top-level layer (the container layer)
        const newLayer = duplicateLayer(layer);
        newLayer.title = this.generateLayerTitle(layer, frameLayers);

        /**
         * Recursively find the layer and copy in the same position.
         * @param layers - The layers to copy.
         */
        const copyLayer = (frameLayers: Layer[]): void => {
            for (const frameLayer of frameLayers) {
                if (frameLayer.key === layer.key) {
                    /**
                     * Find the index of the layer and add the new layer in the next position.
                     */
                    const oldLayerIndex = frameLayers.indexOf(frameLayer);

                    if (oldLayerIndex === -1) {
                        throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
                    }

                    /**
                     * If the position of the layer is relative, add the new layer behind the current layer in the array. So it is below in the canvas.
                     * If the layer is the first layer, add the new layer to the beginning of the array.
                     * Otherwise, add the new layer to the index of the old layer.
                     */
                    const generalProperties = getTemplateData<LayerProperties>(`layerProperties.general.${frameType}.${layer.key}`);

                    if (generalProperties.properties.position === PositionOptions.Relative) {
                        frameLayers.splice(oldLayerIndex + 1, 0, newLayer);
                    } else if (oldLayerIndex === 0) {
                        frameLayers.unshift(newLayer);
                    } else {
                        frameLayers.splice(oldLayerIndex, 0, newLayer);
                    }

                    // Break the loop after copying to avoid duplicating the copied layer.
                    break;
                }

                // Recursively call the function for child layers.
                if (frameLayer.children && frameLayer.children.length > 0) {
                    copyLayer(frameLayer.children);
                }
            }
        };

        copyLayer(frameLayers);

        // Check the title of the child layers.
        if (newLayer.children && newLayer.children.length > 0) {
            const checkTitle = (layers: Layer[]) => {
                for (const layer of layers) {
                    layer.title = this.generateLayerTitle(layer, frameLayers);

                    if (layer.children && layer.children.length > 0) {
                        checkTitle(layer.children);
                    }
                }
            };

            checkTitle(newLayer.children);
        }

        TemplateDesignerStore.save([...changes, [`layers.${frameType}`, frameLayers], ['state.selectedLayers', [newLayer]]]);
    }

    /**
     * Duplicate the layer to the given frame types.
     * @param layerKey - Layer to duplicate.
     * @param frameTypes - Frame types to duplicate the layer to.
     */
    static duplicateLayerToFrames(layerKey: Layer['key'], frameTypes: FrameType['key'][]): void {
        const layer = LayerHelpers.findLayer(layerKey);

        if (!layer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        const formats = getTemplateData<Template['formats']>('formats');
        const currentFrameType = getTemplateData<View['frameType']>('view.frameType');
        const layers = getTemplateData<Template['layers']>('layers');
        const changes: MultiModel = [];

        frameTypes.forEach((frameType) => {
            /**
             * Duplicate layer with all properties.
             * @param layer - Layer to duplicate.
             * @returns - Duplicated layer.
             */
            const duplicateLayer = (layer: Layer): Layer => {
                const newKey = generateKey(layer.type);
                const duplicatedLayer: Layer = cloneDeep(layer);
                duplicatedLayer.key = newKey;

                // Retrieve and store the styling information using the original key
                const generalProperties = getTemplateData<LayerProperties>(`layerProperties.general.${currentFrameType}.${layer.key}`);

                changes.push([`layerProperties.general.${frameType}.${newKey}`, generalProperties]);

                formats.forEach((format) => {
                    const formatOverwrites = getTemplateData<LayerProperties>(`layerProperties.${format.key}.${currentFrameType}.${layer.key}`);
                    if (!formatOverwrites) return;

                    changes.push([`layerProperties.${format.key}.${frameType}.${newKey}`, formatOverwrites]);
                });

                // Recursively duplicate children
                if (layer.children && layer.children.length > 0) {
                    duplicatedLayer.children = layer.children.map((child) => duplicateLayer(child));
                }

                return duplicatedLayer;
            };

            const newLayer = duplicateLayer(layer);
            layers[frameType].unshift(newLayer);
        });

        TemplateDesignerStore.save([...changes, ['layers', layers]]);
    }

    /**
     * Change the key of the layer and its children.
     * @param layers - The layers to change the keys of.
     * @returns The layers with changed keys.
     */
    static changeAllLayerKeys(layers: Layer[]): Layer[] {
        const newLayers: Layer[] = cloneDeep(layers);
        return newLayers.map((child) => {
            const childLayer: Layer = cloneDeep(child);
            childLayer.key = generateKey(child.type);

            if (childLayer.type !== 'text' && childLayer.children) {
                childLayer.children = LayerHelpers.changeAllLayerKeys(childLayer.children);
            }

            return childLayer;
        });
    }

    /**
     * Move the layer up or down in the current context.
     * @param layerKey - The key of the layer to move.
     * @param direction - The direction to move the layer in.
     */
    static moveLayer(layerKey: Layer['key'], direction: 'up' | 'down'): void {
        const layer = LayerHelpers.findLayer(layerKey);

        if (!layer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        const layers = getTemplateData<Template['layers']>('layers');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const frameLayers = layers[frameType];

        /**
         * Move the layer in the layers object.
         * @param layers - The layers to move the layer in.
         * @param currentIndex - The index of the layer to move.
         */
        function moveLayer(layers: Layer[], currentIndex: number) {
            const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

            if (newIndex < 0 || newIndex >= layers.length) return;

            const removedLayer = layers.splice(currentIndex, 1)[0];
            layers.splice(newIndex, 0, removedLayer);
        }

        /**
         * Recursive function that moves the layer in the layers object.
         * @param layers - The layers to move the layer in.
         */
        function findLayerToMove(layers: Layer[]) {
            for (const layer of layers) {
                if (layer.key === layerKey) {
                    const currentIndex = layers.findIndex((childLayer) => childLayer.key === layerKey);
                    return moveLayer(layers, currentIndex);
                }

                if (layer.children) {
                    const currentIndex = layer.children.findIndex((childLayer) => childLayer.key === layerKey);
                    if (currentIndex > -1) {
                        return moveLayer(layer.children, currentIndex);
                    }

                    findLayerToMove(layer.children);
                }
            }
        }

        findLayerToMove(frameLayers);

        TemplateDesignerStore.save([[`layers.${frameType}`, frameLayers]]);
    }

    /**
     * Select the previous or next layer.
     * @param direction - The direction to select the layer in.
     */
    static selectPrevNextLayer = (direction: 'prev' | 'next'): void => {
        const selectedLayers = getTemplateData<State['selectedLayers']>('state.selectedLayers');
        if (!selectedLayers.length) return;

        const layers = getTemplateData<Template['layers']>('layers');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const frameLayers = layers[frameType];

        const selectedLayer = selectedLayers[0];

        const findNewSelectedLayer = (layers: Layer[]): Layer | null => {
            for (const layer of layers) {
                if (layer.key === selectedLayer.key) {
                    const indexSelectedLayer = layers.findIndex((layer) => layer.key === selectedLayer.key);
                    return direction === 'prev' ? layers[indexSelectedLayer - 1] : layers[indexSelectedLayer + 1] || null;
                }

                if (layer.children && layer.children.length > 0) {
                    const foundLayer = findNewSelectedLayer(layer.children);
                    if (foundLayer) {
                        return foundLayer;
                    }
                }
            }

            return null;
        };

        const newSelectedLayer = findNewSelectedLayer(frameLayers);
        if (!newSelectedLayer) return;

        return TemplateDesignerStore.save([['state.selectedLayers', [newSelectedLayer]]]);
    };

    /**
     * Find the path to a layer in the layers object
     * @param layers array of layers to find target in
     * @param targetKey key of target layer to find
     * @param currentPath current path to layer (for recursion)
     * @returns Path of the layer.
     */
    static findLayerPath(layers: Layer[] | undefined, targetKey: Layer['key'], currentPath = ''): string | null {
        if (layers === undefined) {
            const frameType = getTemplateData<View['frameType']>('view.frameType');
            layers = getTemplateData<Layer[]>(`layers.${frameType}`, { clone: false });
        }

        for (const layer of layers) {
            const newPath = currentPath ? `${currentPath}.${layer.key}` : layer.key;

            if (layer.key === targetKey) {
                return newPath;
            }

            if (layer.children && layer.children.length > 0) {
                const pathInChild = this.findLayerPath(layer.children, targetKey, newPath);

                if (pathInChild) {
                    return pathInChild;
                }
            }
        }

        return null;
    }

    /**
     * Find the layer container of the given layer key.
     * @param layerKey - The key of the layer to find the container of.
     * @param layers - The layers to search in.
     * @param frameType - The frame type to search in.
     * @returns The layer container or null.
     */
    static findLayerParent = (layerKey: Layer['key'], layers?: Layer[], frameType?: View['frameType']): Layer | null => {
        if (layers === undefined && frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');
        if (layers === undefined) layers = getTemplateData<Layer[]>(`layers.${frameType}`, { clone: false });

        const findContainer = (layers: Layer[], layerKey: string): Layer | null => {
            for (const layer of layers) {
                if (layer.children && layer.children.length > 0) {
                    const foundLayer = layer.children.find((child) => child.key === layerKey);

                    if (foundLayer) {
                        return layer;
                    }

                    const parentLayer = findContainer(layer.children, layerKey);
                    if (parentLayer) {
                        return parentLayer;
                    }
                }
            }

            return null;
        };

        if (!layers) return null;

        return cloneDeep(findContainer(layers, layerKey));
    };

    /**
     * Get the Material Design Icon for the layer.
     * @param layerType - The type of the layer.
     * @returns The layer icon in custom svg icon format.
     */
    static getLayerIcon(layerType: Layer['type'] | 'button'): GenericSvgIcon {
        switch (layerType) {
            case 'container':
                return 'container';
            case 'text':
            case 'AE_text':
            case 'ID_TextFrame':
                return 'text';
            case 'image':
            case 'AE_image':
            case 'ID_Image':
                return 'image';
            case 'video':
            case 'AE_video':
                return 'video';
            case 'audio':
            case 'AE_audio':
                return 'audio';
            case 'lottie':
                return 'lottie';
            case 'ID_PDF':
                return 'pdf';
            case 'button':
                return 'button';
            case 'shape':
            case 'AE_shape':
            case 'ID_Rectangle':
            case 'AE_misc':
            case 'ID_Layer':
            case 'ID_Polygon':
            default:
                return 'dashboard';
        }
    }

    /**
     * Check if the layer is a media layer.
     * @param layerType - The type of the layer.
     * @returns True if the layer is a media layer.
     */
    static isMediaLayer = (layerType: Layer['type']): boolean => {
        return ['video', 'audio'].includes(layerType);
    };

    /**
     * Check if the layer supports animations.
     * @param layerType - The type of the layer.
     * @returns True if the layer supports animations.
     */
    static layerSupportsAnimations = (layerType: Layer['type']): boolean => {
        return !['audio'].includes(layerType);
    };

    /**
     * Check if the layer can be edited.
     * @param layer - The layer to check.
     * @param templateType - The type of the template.
     * @returns True if the layer can be edited.
     */
    static canEditMediaLayer = (layer: Layer, templateType?: TemplateData['type']): boolean => {
        if (templateType === undefined) templateType = getTemplateData<TemplateData['type']>('templateData.type');

        if (templateType !== 'displayAdDesigned') return true;

        const isMediaLayer = LayerHelpers.isMediaLayer(layer.type);
        if (!isMediaLayer) return true;

        const isSuperAdmin = User.get('type') === 'superadmin';
        return isSuperAdmin;
    };
}

export default LayerHelpers;
