import merge from 'lodash/merge';
import LayerProperties, { BorderStyleOptions } from '../types/layerProperties.type';
import Template, { View } from '../types/template.type';
import { getTemplateData } from './data.helpers';
import FormatProperties from '../types/formatProperties.type';
import FrameType from '../types/frameTypes.type';
import LayerHelpers from './layer.helpers';
import { LayerPropertiesHelpers } from './layer-properties.helpers';

class LayerCalculationHelpers {
    /**
     * This gets the width or height of a layer
     * @param size - Which size you want to retrieve; width or height
     * @param layerProperties - Properties of the layer you want to get the size of
     * @param containerSize - Width or height of the container layer (another layer or format widht/height)
     * @param targetLayer - The DOM Element of the layer you want to get the size of
     * @returns The width or height of the layer.
     */
    static getLayerSize = (size: 'width' | 'height', layerProperties: LayerProperties, containerSize: number, targetLayer?: HTMLElement | null): number => {
        const properties = layerProperties.properties;
        const animations = layerProperties.animations;

        if (animations?.size?.length) {
            if (size === 'height') return targetLayer?.clientHeight || 0;
            return targetLayer?.clientWidth || 0;
        }

        const value = properties[size].value;

        // if the value is an empty string it means the user didn't set a value so we return the clientWidth or clientHeight of the layer
        // the layer size is based on the children of the layer
        if (value === '') {
            if (size === 'height') return targetLayer?.clientHeight || 0;
            return targetLayer?.clientWidth || 0;
        }

        // if the unit is set to percentages we need to calculate the value based on the container size
        if (properties[size].unit === '%' && typeof value === 'number') {
            return (value / 100) * containerSize;
        }

        return value as number;
    };

    /**
     * get the container size of an layer
     * @param layerKey layer key to get the container size from
     * @param formatKey the specific format you want to use
     * @returns the container size of the layer
     */
    static getContainerSize = (
        layerKey: string,
        formatKey: string,
        frameType: FrameType['key'] = getTemplateData<View['frameType']>('view.frameType')
    ): { width: number; height: number; x: number; y: number } => {
        const containerLayer = LayerHelpers.findLayerParent(layerKey, undefined, frameType);
        const domLayer = document.querySelector<HTMLElement>(`.layers-container__layer.${layerKey}.${formatKey}`);
        const domContainerLayer = domLayer && (domLayer.parentNode as HTMLElement);

        // if we cannot find a container layer it means that the format is our container so return format width/height
        if (!containerLayer) {
            const formatProperties = ((): FormatProperties => {
                const containerGeneralLayerProperties = getTemplateData<FormatProperties>(`layerProperties.general.${frameType}.properties`);
                const containerFormatLayerProperties = getTemplateData<FormatProperties>(`layerProperties.${formatKey}.${frameType}.properties`);
                return merge(containerGeneralLayerProperties, containerFormatLayerProperties);
            })();

            const borderWidth = (() => {
                if (formatProperties.border?.borderStyle === BorderStyleOptions.None) return 0;
                return (formatProperties.border?.borderWidth.left.value || 0) + (formatProperties.border?.borderWidth.right.value || 0);
            })();
            const borderHeight = (() => {
                if (formatProperties.border?.borderStyle === BorderStyleOptions.None) return 0;
                return (formatProperties.border?.borderWidth.top.value || 0) + (formatProperties.border?.borderWidth.bottom.value || 0);
            })();

            const format = getTemplateData<Template['formats']>('formats').find((format) => format.key === formatKey);

            // if format width is set return format width
            // if there is a border set we need to remove the border width twice (left and right or top and bottom) from the format width
            // as the border width makes the format width/height smaller
            const formatWidth = format?.width ? format.width - borderWidth : 0;
            const formatHeight = format?.height ? format.height - borderHeight : 0;

            return { width: formatWidth, height: formatHeight, x: 0, y: 0 };
        }

        const containerInitialPosition: LayerProperties | undefined = (() => {
            const containerGeneralLayerProperties = getTemplateData<LayerProperties>(`layerProperties.general.${frameType}.${containerLayer.key}`);
            const containerFormatLayerProperties = getTemplateData<LayerProperties>(`layerProperties.${formatKey}.${frameType}.${containerLayer.key}`);

            return LayerPropertiesHelpers.mergeLayerProperties(
                containerLayer.key,
                frameType,
                [formatKey],
                undefined,
                containerGeneralLayerProperties,
                containerFormatLayerProperties
            ).layerProps;
        })();

        // if we cannot find the container layer properties it means that something is wrong with template. Throw an error
        if (!containerInitialPosition) throw new Error("Couldn't find container layer properties for " + containerLayer.key + ' in ' + formatKey + ' format');

        const nextContainerSize = this.getContainerSize(containerLayer.key, formatKey);

        const borderWidth = (() => {
            if (containerInitialPosition.properties?.border?.borderStyle === BorderStyleOptions.None) return 0;
            if (!containerInitialPosition.properties?.border?.borderWidth) return 0;

            return (
                (containerInitialPosition.properties.border.borderWidth.left.value || 0) +
                (containerInitialPosition.properties.border.borderWidth.right.value || 0)
            );
        })();
        const borderHeight = (() => {
            if (containerInitialPosition.properties?.border?.borderStyle === BorderStyleOptions.None) return 0;
            if (!containerInitialPosition.properties?.border?.borderWidth) return 0;

            return (
                (containerInitialPosition.properties.border.borderWidth.top.value || 0) +
                (containerInitialPosition.properties.border.borderWidth.bottom.value || 0)
            );
        })();

        const containerWidth = (() => {
            // get the next container width as the next container also have percentages
            // this goes till there are no container layers left then we use the format width
            const nextContainerWidth = nextContainerSize.width;
            // if there is a border width set we need to remove the border width twice (top and bottom or left and right) from the container width
            // as the border width makes the container width/height smaller
            return this.getLayerSize('width', containerInitialPosition, nextContainerWidth, domContainerLayer) - borderWidth;
        })();

        const containerHeight = (() => {
            // get the next container width as the next container also have percentages
            // this goes till there are no container layers left then we use the format width
            const nextContainerHeight = nextContainerSize.height;
            // if there is a border width set we need to remove the border width twice (top and bottom or left and right) from the container width
            // as the border width makes the container width/height smaller
            return this.getLayerSize('height', containerInitialPosition, nextContainerHeight, domContainerLayer) - borderHeight;
        })();

        const containerX = (() => {
            // get the next container width as the next container also have percentages
            // this goes till there are no container layers left then we use the format width
            const nextContainerWidth = nextContainerSize.width;

            const properties = containerInitialPosition.properties;

            const value = properties.x.value;

            // if the value is an empty string it means the user didn't set a value so we return the clientWidth or clientHeight of the layer
            // the layer size is based on the children of the layer
            if (value === '') {
                return domLayer?.clientLeft || 0;
            }

            // if the unit is set to percentages we need to calculate the value based on the container size
            if (properties.x.unit === '%' && typeof value === 'number') {
                return (value / 100) * nextContainerWidth;
            }

            return value as number;
        })();

        const containerY = (() => {
            // get the next container width as the next container also have percentages
            // this goes till there are no container layers left then we use the format width
            const nextContainerHeight = nextContainerSize.height;

            const properties = containerInitialPosition.properties;

            const value = properties.y.value;

            // if the value is an empty string it means the user didn't set a value so we return the clientWidth or clientHeight of the layer
            // the layer size is based on the children of the layer
            if (value === '') {
                return domLayer?.clientTop || 0;
            }

            // if the unit is set to percentages we need to calculate the value based on the container size
            if (properties.y.unit === '%' && typeof value === 'number') {
                return (value / 100) * nextContainerHeight;
            }

            return value as number;
        })();

        return {
            width: containerWidth,
            height: containerHeight,
            x: containerX,
            y: containerY
        };
    };
}

export { LayerCalculationHelpers };
